<template>
  <div class="layout">
    <div class="sidebar">
      <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        style="height: 100vh"
      >
        <el-menu-item @click="isCollapse = !isCollapse">
          <div>
            <i v-if="isCollapse" class="el-icon-s-unfold"></i>
            <i v-else class="el-icon-s-fold"></i>
            <span>Menu</span>
          </div>
        </el-menu-item>
        <el-menu-item index="1" @click="entity = 'widget'">
          <i class="el-icon-postcard"></i>
          <span>Widget</span>
        </el-menu-item>
        <el-menu-item index="2" @click="entity = 'account'">
          <i class="el-icon-user-solid"></i>
          <span>Conta</span>
        </el-menu-item>
        <el-menu-item @click="logoutFB">
          <i class="el-icon-back"></i>
          <span>Sair</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div>
      <app-container class="dashboard">
        <div v-show="entity == 'widget'">
          <h1>Feed</h1>
          <div
            style="display: flex; flex-wrap: nowrap; align-items: flex-start"
          >
            <el-input
              placeholder="Nome de usuário do instagram"
              v-model="inputUserName"
              style="margin-bottom: 24px; max-width: 360px; margin-right: 16px"
              @keyup.enter="reload"
            >
            </el-input>
            <el-button type="primary" plain @click="reload">
              Carregar
            </el-button>
          </div>
          <instagram-feed
            v-if="userName"
            :user-name="userName"
            @loaded="load => (loaded = load)"
          ></instagram-feed>

          <div v-if="loaded === false">
            <p>
              Se for dono desta conta você pode autoriza-la clicando no botão
              abaixo
            </p>
            <el-button @click="authorize">
              <div style="display: flex; align-items: center">
                <img
                  src="https://facebookbrand.com/wp-content/uploads/2021/03/glyph-logo_May2016.png?w=24"
                  alt="Instagram"
                  style="margin-right: 16px"
                />

                <span>Autorizar com o Instagram</span>
              </div>
            </el-button>
          </div>

          <div v-if="userName && loaded">
            <p>Adicione o código abaixo em seu site:</p>
            <div class="code">
              <code>
                <pre>{{`<div id="instagram-feed"></div>
<script src="http://localhost/instagram/api/assets/js/instagram.js" data-username="${userName}"></script>`}}</pre>
              </code>
            </div>
            <div v-if="loaded" style="margin-top: 24px">
              <a :href="url" target="_blank">
                <el-button type="danger" plain @click="unauthorize">
                  Desautorizar Instagram
                </el-button>
              </a>
            </div>
          </div>
        </div>
        <div v-show="entity == 'account'">
          <h1>Conta</h1>
          <p>
            {{ user ? user.userName : '' }}
          </p>
          <el-button type="danger" plain @click="logoutFB">
            Excluir conta
          </el-button>
        </div>
      </app-container>
      <the-footer></the-footer>
    </div>
  </div>
</template>

<style lang="sass" scoped>
p
  margin: 24px 0
.layout
  display: flex
.el-menu-vertical-demo:not(.el-menu--collapse)
  width: 200px
  min-height: 400px
.code
  border: 1px solid #ddd
  border-radius: 4px
  padding: 24px
  background-color: #fafafa
</style>

<script>
import api from '@/services/api'
import AppContainer from '@/components/AppContainer'
import InstagramFeed from '@/components/InstagramFeed'
import TheFooter from '@/components/TheFooter'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DashBoard',
  components: { AppContainer, InstagramFeed, TheFooter },
  data() {
    return {
      isCollapse: false,
      entity: 'widget',
      userName: '',
      inputUserName: '',
      loaded: null
    }
  },
  created() {
    const { code } = this.$route.params
    if (code) {
      api
        .get(`/authorize/${code}`)
        .then(({ data }) => {
          if (data.userName) {
            this.inputUserName = data.userName
            this.reload()
          }
        })
        .catch(console.error)
        .finally(() =>
          history.replaceState(
            null,
            document.title,
            window.location.href.replace(/(.*\/dashboard).*/, '$1')
          )
        )
    }

    api
      .get('/url/dashboard')
      .then(({ data: { url } }) => {
        this.url = url
      })
      .catch(console.error)

    window.FB.getLoginStatus() // se remover da erro no logout
  },
  mounted() {
    // this.userName = this.user.userName
    // this.inputUserName = this.user.userName
  },
  watch: {
    status: {
      handler(value) {
        if (!value.loggedIn) {
          this.$router.push('/login')
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState('auth', ['user', 'status'])
  },
  methods: {
    ...mapActions('auth', ['logout']),
    logoutFB() {
      window.FB.logout(() => {
        this.logout()
      })
    },
    reload() {
      this.userName = this.inputUserName
    },
    authorize() {
      window.location.href = this.url
    },
    unauthorize() {
      this.userName = ''
      this.inputUserName = ''
      this.loaded = null
    }
  }
}
</script>
